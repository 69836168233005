<template>
  <v-dialog v-model="isOpen" fullscreen persistent width="100%">
    <v-card v-if="!userLoading" class="h-100 w-100">
      <page-title :title="getTableTitle" />
      <v-row :align="`center`" no-gutters style="height: 100vh">
        <v-col v-if="$vuetify.breakpoint.width >= 1150" class="h-100">
          <SliderContainer />
        </v-col>

        <v-col class="h-100">
          <v-row :align="`center`" justify="center" style="height: 100%">
            <v-col cols="8">
              <div>
                <v-img
                  v-if="heightGreaterThan850"
                  src="@/assets/img/brand/Symbo_Icon_Primary.svg"
                  height="50"
                  width="50"
                  class="mb-3"
                />
                <span
                  class="pt-3 font-thiccboi font-weight-bold"
                  :style="
                    heightGreaterThan850
                      ? 'font-size: 2.5em'
                      : 'font-size: 1.5em'
                  "
                >
                  Phone Verification
                </span>
                <div
                  class="pt-2 pb-6 px-1 grey--text text--darken-4"
                  :style="
                    heightGreaterThan850
                      ? 'font-size: 1.2em'
                      : 'font-size: 1.0em'
                  "
                >
                  Verify your phone through OTP verification. Choose sms or
                  voice call to get a verification code (OTP) on your phone.
                </div>

                <v-row>
                  <!-- Pre-send otp -->
                  <template>
                    <!-- Phone -->
                    <v-col
                      cols="12"
                      class="mb-4"
                      :class="heightGreaterThan850 ? 'py-2' : 'pb-1'"
                    >
                      <div
                        class="font-weight-medium mb-1 grey--text text--darken-3"
                      >
                        Phone number:
                      </div>
                      <div class="d-flex align-center">
                        <country-code-select v-model="countryCode" />

                        <v-text-field
                          v-model="contactPhone"
                          dense
                          outlined
                          required
                          full-width
                          hide-details
                          placeholder="Phone Number"
                          class="rounded-lg ml-2"
                          :readonly="!!otpTimeout"
                        />
                      </div>
                    </v-col>
                    <!-- OTP Method -->
                    <v-col
                      cols="12"
                      class="mb-3"
                      :class="heightGreaterThan850 ? 'py-2' : 'py-1'"
                    >
                      <div
                        class="font-weight-medium mb-1 grey--text text--darken-3"
                      >
                        Method:
                      </div>
                      <v-radio-group
                        v-model="otpMethod"
                        row
                        class="my-0 py-0"
                        hide-details
                      >
                        <v-radio label="SMS" value="sms" />
                        <v-radio label="Voice Call" value="voice" />
                      </v-radio-group>
                    </v-col>
                  </template>

                  <!-- Post-send otp -->
                  <template v-if="otpTimeout">
                    <!-- Verify OTP -->
                    <v-col
                      cols="12"
                      :class="heightGreaterThan850 ? 'py-2' : 'pb-1'"
                    >
                      <div
                        class="font-weight-medium mb-1 grey--text text--darken-3"
                      >
                        Verification code:
                      </div>
                      <v-text-field
                        v-model="otp"
                        dense
                        outlined
                        required
                        full-width
                        hide-details
                        placeholder="Enter 6 digit verification code"
                        class="rounded-lg"
                        @keydown.enter="onKeypressEnter"
                      />
                    </v-col>

                    <!-- OTP Timeout -->
                    <div
                      v-if="otpTimeout"
                      class="font-weight-medium d-flex align-center justify-end w-100 mb-2 mr-2 grey--text text--darken-2 px-3"
                    >
                      <div class="mr-2">Expires in:</div>
                      <div>
                        {{ otpTimeoutLabel }}
                      </div>
                    </div>

                    <!-- Fall back -->
                    <div class="mt-3 ml-3 d-flex align-center text-caption">
                      <v-icon x-small class="mr-2">mdi-information</v-icon>
                      <div class="font-weight-medium">
                        If you're having trouble getting a verification code
                        <span class="link" @click="contactSupport">
                          contact support
                        </span>
                      </div>
                    </div>
                  </template>

                  <!-- Actions -->
                  <v-col cols="12">
                    <v-tooltip
                      v-if="otpTimeout"
                      bottom
                      open-delay="200"
                      :disabled="!disableVerifyBtn"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            block
                            color="green"
                            class="rounded-lg white--text font-weight-medium"
                            style="font-size: 1em"
                            :disabled="disableVerifyBtn"
                            :large="heightGreaterThan850"
                            :loading="verifyOTPLoader"
                            @click="verifyOTP"
                          >
                            Verify code
                          </v-btn>
                        </div>
                      </template>
                      Please enter a 6 digit OTP
                    </v-tooltip>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-tooltip
                      bottom
                      open-delay="200"
                      :disabled="!disableOTPButton"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            block
                            color="primary"
                            class="rounded-lg white--text font-weight-medium"
                            style="font-size: 1em"
                            :disabled="disableOTPButton"
                            :large="heightGreaterThan850"
                            :loading="sendOTPLoader"
                            @click="sendOTP"
                          >
                            {{
                              otpTimeout
                                ? 'Resend verification code'
                                : 'Send verification code'
                            }}
                          </v-btn>
                        </div>
                      </template>
                      Please enter a valid phone number to verify
                    </v-tooltip>
                  </v-col>

                  <v-col cols="12">
                    <v-btn
                      block
                      color="grey"
                      class="rounded-lg white--text font-weight-medium"
                      style="font-size: 1em"
                      :large="heightGreaterThan850"
                      @click="userLogout"
                    >
                      {{ isHijackMode ? 'Exit Hijack' : 'Logout' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SliderContainer from '@/components/login/SliderContainer.vue'
import CountryCodeSelect from './CountryCodeSelect.vue'

import PhoneVerificationApi from '@/services/api/PhoneVerification'
import { EventBus } from '@/services/event-bus'

import { getHelpFormValue } from '@/constants'

import { convertSecondsToMinsSecs } from '@/utils/helpers'
import { parsePhoneNumber } from 'libphonenumber-js'
import { HIJACK_LS_KEY, isHijackMode } from '@/utils/localStorage'

export default {
  components: { SliderContainer, CountryCodeSelect },
  data: () => ({
    countryCode: 'US',
    otp: '',
    otpMethod: 'sms',
    contactPhone: '',
    sendOTPLoader: false,
    verifyOTPLoader: false,
    otpTimeout: 0,
    otpSessionId: null,
    otpInterval: null,
    token: null,
    isOpen: false,
  }),
  computed: {
    ...mapGetters('user', [
      'user',
      'isVerifiedUser',
      'userLoading',
      'userFetchRequest',
      'isHijackMode',
    ]),
    ...mapGetters('billingPlans', ['billingPlans']),
    getTableTitle() {
      return this.userLoading || this.userFetchRequest
        ? 'Symbo'
        : this.$route.name === 'BookSlot'
        ? 'Book meeting'
        : 'Phone Verification | Symbo'
    },
    disableOTPButton() {
      return !this.contactPhone || this.contactPhone.length < 10
    },
    disableVerifyBtn() {
      return !this.otp || this.otp.length < 6
    },
    heightGreaterThan850() {
      return this.$vuetify.breakpoint.height >= 850
    },
    otpTimeoutLabel() {
      return this.otpTimeout
        ? convertSecondsToMinsSecs(this.otpTimeout)
        : '00:00'
    },
  },
  methods: {
    ...mapActions('user', ['load', 'logout']),
    ...mapActions('billingPlans', ['fetchBillingPlans']),
    userLogout() {
      if (isHijackMode()) {
        localStorage.removeItem(HIJACK_LS_KEY)
        location.reload()
      } else {
        this.logout()
        this.$router.push('/login')
      }
    },
    contactSupport() {
      EventBus.$emit('open-help-form', getHelpFormValue('GENERAL_SUPPORT'), {
        description: `Hi. I need help with my phone verification. I am trying to verify my phone number ${this.contactPhone}, but I am not receiving the OTP.`,
      })
    },
    onKeypressEnter() {
      if (!this.disableOTPButton) this.verifyOTP()
    },
    sendOTP() {
      const parsedPhone = parsePhoneNumber(this.contactPhone, this.countryCode)

      if (!parsedPhone || !parsedPhone.isValid()) {
        this.$store.commit('snackbar/setSnack', {
          snack: 'Invalid format or incorrect number',
          snackType: 'error',
        })
        return
      }
      // Stop the previous interval in case of 'resend'
      if (this.otpInterval) this.stopOtpInterval()

      this.sendOTPLoader = true
      PhoneVerificationApi.sendOtp({
        number: parsedPhone.number,
        channel: this.otpMethod,
      })
        .then((res) => {
          this.otpSessionId = res.otp_session_id
          this.otpTimeout = 180
          this.startOtpInterval()
        })
        .finally(() => (this.sendOTPLoader = false))
    },
    verifyOTP() {
      this.verifyOTPLoader = true
      PhoneVerificationApi.verifyOtp({
        otp_session_id: this.otpSessionId,
        otp: this.otp,
        update_user_contact_phone:
          this.contactPhone !== this.user.contact_phone,
      })
        .then(() => this.load())
        .finally(() => (this.verifyOTPLoader = false))
    },
    startOtpInterval() {
      this.otpInterval = setInterval(() => {
        if (this.otpTimeout <= 0) this.stopOtpInterval()
        this.otpTimeout = this.otpTimeout - 1
      }, 1000)
    },
    stopOtpInterval() {
      clearInterval(this.otpInterval)
      this.otpInterval = null
      this.otpTimeout = 0
    },
  },
  watch: {
    isOpen(val, oldVal) {
      // Ask for lite mode or full mode
      if (!val && oldVal) EventBus.$emit('open-workflow-dialog')
    },
    user: {
      handler(val) {
        if (val && val.contact_phone && !this.contactPhone) {
          let parsedPhone
          try {
            parsedPhone = parsePhoneNumber(val.contact_phone)
          } catch (e) {
            parsedPhone = parsePhoneNumber(val.contact_phone, 'US')
          }

          this.contactPhone = parsedPhone.nationalNumber
          this.countryCode = parsedPhone.country
        }
      },
      deep: true,
    },
    userLoading(val) {
      if (!val) {
        setTimeout(() => {
          if (!this.isVerifiedUser) this.isOpen = true
          else this.isOpen = false
        }, 300)
      }
    },
    otpInterval(val) {
      if (!val) this.stopOtpInterval()
    },
  },
  async mounted() {
    await this.$store.dispatch('user/load')
    const planInRoute = this.$route.query?.planId
    if (this.isVerifiedUser && planInRoute) {
      this.fetchBillingPlans().then(() => {
        this.$router.push('/').then(() => {
          if (planInRoute) {
            const planObj = this.billingPlans.find((p) => p.id === planInRoute)

            if (planObj)
              EventBus.$emit(
                'open-help-form',
                getHelpFormValue('GENERAL_SUPPORT'),
                {
                  description: `Hi. I need to update my subscription to ${planObj.name}.`,
                }
              )
          }
        })
      })
    }
  },
}
</script>
