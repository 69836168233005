export const WORKFLOWS = Object.freeze([
  { name: 'PD_UPGRADE', value: '67b2b5919b38e1d1dc6a7794' },
  { name: '10DLC_REQUEST', value: '67a93739de0a3000de026068' },
])

export default WORKFLOWS

const HELP_FORMS = Object.freeze([
  { name: 'GENERAL_SUPPORT', value: 'a785d138ee16a65c7cc6' },
])

export function getWorkflowValue(name) {
  if (!name) return
  return WORKFLOWS.find((i) => i.name === name)?.value
}

export function getHelpFormValue(name) {
  if (!name) return
  return HELP_FORMS.find((i) => i.name === name)?.value
}
