<template>
  <v-menu v-model="countryCodeMenu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-card
        v-on="on"
        outlined
        width="80"
        height="40"
        class="d-flex align-center px-3"
      >
        <div class="text-h4 d-flex align-center" style="height: 40px">
          {{ selectedCountryObj?.flag }}
        </div>
        <v-spacer />
        <v-icon small>mdi-chevron-down</v-icon>
      </v-card>
    </template>
    <v-card class="py-4 px-3" max-width="300">
      <v-text-field
        v-model="countryFilter"
        dense
        outlined
        autofocus
        hide-details
        placeholder="Search"
        prepend-inner-icon="mdi-magnify"
      />
      <v-list
        v-if="filteredCountryOpts?.length"
        dense
        max-height="200"
        class="overflow-y-auto"
      >
        <v-list-item
          v-for="(item, index) in filteredCountryOpts"
          :key="index"
          :class="{
            'bg-selected': item.code === selectedCountryCode,
          }"
          class="d-flex align-cente"
          @click="
            selectedCountryCode = item.code
            countryCodeMenu = false
            countryFilter = ''
          "
        >
          <div>{{ item.flag }}</div>
          <div class="mx-2 text-truncate">{{ item.name }}</div>
          <div>{{ item.callingCode }}</div>
        </v-list-item>
      </v-list>
      <div
        v-else
        class="d-flex align-center text-body-2 justify-center py-3 font-weight-light font-italic"
      >
        No country found
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { getCountries, getCountryCallingCode } from 'libphonenumber-js'

const _sortBy = require('lodash/sortBy')
const countries = require('country-data').countries

export default {
  data: () => ({
    countryCodeMenu: false,
    countryFilter: '',
    countryOpts: [],
  }),
  props: {
    value: {
      default: null,
    },
  },
  computed: {
    selectedCountryCode: {
      get() {
        return this.value
      },
      set(newVal) {
        return this.$emit('input', newVal)
      },
    },

    filteredCountryOpts() {
      const normalizedQuery = this.countryFilter.toLowerCase()
      return this.countryOpts.filter(
        (item) =>
          item.name.toLowerCase().includes(normalizedQuery) ||
          item.callingCode.includes(normalizedQuery) ||
          item.code.toLowerCase().includes(normalizedQuery)
      )
    },

    selectedCountryObj() {
      return this.countryOpts.find(
        (item) => item.code === this.selectedCountryCode
      )
    },
  },
  methods: {
    getFlagFromCountryCode(code) {
      return countries[code].emoji
    },
    getNameFromCountryCode(code) {
      return countries[code].name
    },
  },
  mounted() {
    this.countryOpts = _sortBy(
      getCountries()
        .map((code) => {
          return {
            code, // US
            flag: this.getFlagFromCountryCode(code), // hdknkcjsh8osoidhbdjs
            name: this.getNameFromCountryCode(code), // United States
            callingCode: `+${getCountryCallingCode(code)}`, // +1
          }
        })
        .filter((item) => !!item.flag),
      'name'
    )
  },
}
</script>
