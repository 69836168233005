const ACTIONS = Object.freeze([
  {
    id: 'import_prospects',
    icon: 'mdi-file-upload',
    name: 'Import prospects',
    key: 'import_prospects',
    description: 'Import prospects from a CSV or Excel file',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'create_prospects',
    icon: 'mdi-account-plus',
    name: 'Create prospect',
    key: 'create_prospects',
    description: 'Add a new prospect record to Symbo',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'create_sequence',
    icon: '$vuetify.icons.symboDrive',
    name: 'Create sequence',
    key: 'create_sequence',
    description:
      'Create a new sequence of actions to be performed on prospects',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'create_account',
    icon: 'mdi-office-building',
    name: 'Create account',
    key: 'create_account',
    description: 'Add a new account record to Symbo',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'start_todays_actions',
    icon: 'mdi-checkbox-marked-circle-outline',
    name: `Start today's actions`,
    key: 'start_todays_actions',
    description:
      'Start an action flow for calls, emails, LinkedIn tasks, and tasks that are due today',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'start_todays_calls',
    icon: 'mdi-checkbox-marked-circle-outline',
    name: `Start today's calls`,
    key: 'start_todays_calls',
    description: 'Start an action flow for calls that are due today',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'start_todays_emails',
    icon: 'mdi-checkbox-marked-circle-outline',
    name: `Start today's emails`,
    key: 'start_todays_emails',
    description: 'Start an action flow for manual emails that are due today',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'start_todays_linkedin_tasks',
    icon: 'mdi-checkbox-marked-circle-outline',
    name: `Start today's LinkedIn tasks`,
    key: 'start_todays_linkedin_tasks',
    description: 'Start an action flow for LinkedIn tasks that are due today',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'start_todays_tasks',
    icon: 'mdi-checkbox-marked-circle-outline',
    name: `Start today's tasks`,
    key: 'start_todays_tasks',
    description: 'Start an action flow for tasks that are due today',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'make_call',
    icon: 'mdi-phone',
    name: 'Make a call',
    key: 'make_call',
    description: 'Make a call to a prospect',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'send_email',
    icon: 'mdi-email',
    name: 'Send an email',
    key: 'send_email',
    description: 'Send an email to a prospect',
    action_type: 'actions',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
])

const ANALYTICS = Object.freeze([
  {
    id: 'view_email_analytics',
    icon: 'mdi-chart-bar',
    name: 'View email analytics',
    key: 'view_email_analytics',
    description: 'View analytics for emails sent to prospects',
    action_type: 'analytics',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'view_call_analytics',
    icon: 'mdi-chart-bar',
    name: 'View call analytics',
    key: 'view_call_analytics',
    description: 'View analytics for calls made to prospects',
    action_type: 'analytics',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'view_team_performance_analytics',
    icon: 'mdi-chart-bar',
    name: 'View team performance analytics',
    key: 'view_team_performance_analytics',
    description: 'View a ranking of team members by activity',
    action_type: 'analytics',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
])

const SETTINGS = Object.freeze([
  {
    id: 'manage_tags',
    icon: 'mdi-tag-multiple',
    name: 'Manage tags',
    key: 'manage_tags',
    description: 'Add, update, or delete tags used to organize prospects',
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_call_outcomes',
    icon: 'mdi-sign-direction',
    name: 'Manage call outcomes',
    key: 'manage_call_outcomes',
    description:
      'Add, update, or delete call outcomes used to track call results',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_call_transfer_groups',
    icon: 'mdi-phone-forward',
    name: 'Manage call transfer groups',
    key: 'manage_call_transfer_groups',
    description:
      'Add, update, or delete call transfer groups used to transfer calls to groups of users',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_ord_wide_phone_settings',
    icon: 'mdi-phone-settings',
    name: 'Manage org wide phone settings',
    key: 'manage_ord_wide_phone_settings',
    description: "Manage your org's phone settings",
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_crm_mappings',
    icon: 'mdi-phone-forward',
    name: 'Manage CRM mappings',
    key: 'manage_crm_mappings',
    description: 'Define how fields in Symbo map to fields in your CRM',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_sequence_outcomes',
    icon: 'mdi-sign-direction',
    name: 'Manage sequence outcomes',
    key: 'manage_sequence_outcomes',
    description:
      'Add, update, or delete sequence outcomes used to track sequence performance and trigger workflows',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_users',
    icon: 'mdi-account-multiple',
    name: 'Manage users',
    key: 'manage_users',
    description: 'Add, update, or delete users',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_prospect_statuses',
    icon: 'mdi-clipboard-account',
    name: 'Manage prospect statuses',
    key: 'manage_prospect_statuses',
    description:
      'Add, update, or delete prospect statuses used to track prospect progress',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_prospect_fields',
    icon: 'mdi-code-json',
    name: 'Manage prospect fields',
    key: 'manage_prospect_fields',
    description:
      'Add, edit, or delete custom prospect fields that you see on all pages',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_prospect_fields_display',
    icon: 'mdi-code-json',
    name: 'Manage prospect fields display',
    key: 'manage_prospect_fields_display',
    description: 'Customize how prospect fields appear on details page',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'add_or_remove_user_seats',
    icon: 'mdi-account-multiple',
    name: 'Manage user seats',
    key: 'manage_user_seats',
    description: 'Add or remove user seats from your subscription',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'edit_email_signature',
    icon: 'mdi-email-edit-outline',
    name: 'Edit email signature',
    key: 'edit_email_signature',
    description:
      'Edit the email signature used in emails sent to prospects from Symbo',
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'email_subscription_preferences',
    icon: 'mdi-email-lock',
    name: 'Email subscription preferences',
    key: 'email_subscription_preferences',
    description:
      'Manage your unsubscribe link preferences for emails sent from Symbo',
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_email_templates',
    icon: 'mdi-email-multiple-outline',
    name: 'Manage email templates',
    key: 'manage_email_templates',
    description:
      'Add, update, or delete email templates used to send one-off or sequence emails',
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_sms_templates',
    icon: 'mdi-message-bookmark-outline',
    name: 'Manage SMS templates',
    key: 'manage_sms_templates',
    description: 'Add, update, or delete SMS templates',
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'change_password',
    icon: 'mdi-lock',
    name: 'Change password',
    key: 'change_password',
    description: 'Change your Symbo password',
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'copy_referral_code',
    icon: 'mdi-content-copy',
    name: 'Copy referral link',
    key: 'copy_referral_code',
    description:
      "If a user signs up with your referral link, you'll receive a kickback as credit in your account or a giftcard",
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'update_user_profile',
    icon: 'mdi-account',
    name: 'Update user profile',
    key: 'update_user_profile',
    description: 'Change your information and profile picture',
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'work_schedule',
    icon: 'mdi-calendar-clock',
    name: 'Work schedule',
    key: 'work_schedule',
    description:
      'Set your work schedule to manage when automated actions are scheduled',
    action_type: 'settings',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'manage_calendar_schedulers',
    icon: 'mdi-calendar-cursor',
    name: 'Manage calendar schedulers',
    key: 'manage_calendar_schedulers',
    description:
      'Add, update, or delete calendar schedulers used to schedule meetings with prospects',
    action_type: 'settings',
    admin_only: true,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
])

const INTEGRATIONS = Object.freeze([
  {
    id: 'crm_integration',
    icon: 'mdi-link-variant',
    name: 'CRM integration',
    key: 'crm_integration',
    description:
      'Manage your HubSpot or Salesforce CRM integration for bi-directional sync with Symbo',
    action_type: 'integrations',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'email_integration',
    icon: 'mdi-link-variant',
    name: 'Email integration',
    key: 'email_integration',
    description:
      'Connect your email and set your throttling preferences for sending emails from Symbo',
    action_type: 'integrations',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'get_new_phone_number',
    icon: 'mdi-phone-settings',
    name: 'Manage outbound phone numbers',
    key: 'get_new_phone_number',
    description: 'Manage phone numbers for making calls from Symbo',
    action_type: 'integrations',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
  {
    id: 'add_forwarding_number',
    icon: 'mdi-phone-settings',
    name: 'Add forwarding number',
    key: 'add_forwarding_number',
    description:
      'Add a forwarding number for receiving calls made to your Symbo phone number(s)',
    action_type: 'integrations',
    admin_only: false,
    symbo_organization_id: 0,
    symbo_user_id: 0,
  },
])

/**
 * Returns univseral search group
 */
export function getUniversalSearchGroup(key) {
  switch (key) {
    case 'actions':
      return Object.values(ACTIONS).map((value) => ({ document: value }))
    case 'analytics':
      return Object.values(ANALYTICS).map((value) => ({ document: value }))
    case 'settings':
      return Object.values(SETTINGS).map((value) => ({ document: value }))
    case 'integrations':
      return Object.values(INTEGRATIONS).map((value) => ({ document: value }))
    default:
      return []
  }
}
