<template>
  <v-app>
    <!-- Messaging components -->
    <Snackbar />
    <AppModal />

    <!-- Help chat -->
    <HelpChat />

    <template v-if="isLoggedIn && isVerifiedUser">
      <!-- Workflow prompt -->
      <workflow-prompt />

      <!-- || Service based components || -->

      <!-- Email -->
      <test-email />
      <email-composer v-if="!isLiteMode" />

      <!-- Dialer -->
      <Dialer
        v-if="$route.path !== '/dial' && (hasCalling || adminCanManageCalls)"
      />
      <power-dial-ongoing-call v-if="hasPowerDialing && ongoingPowerDialCall" />

      <!-- Contact components -->
      <CSVUploader ref="csvUploader" :sheetLisence="peopleSheetLicense" />
      <create-person />
      <contact-details-dialog v-if="isLiteMode" />

      <!-- Account components  -->
      <create-account />

      <!-- Action flow dialog -->
      <action-flow-mode v-if="!isLiteMode" />

      <!-- Other -->
      <CRMReauthSnackbar v-if="!isLiteMode" />
      <CustomizeColumnsDrawer
        v-if="getSettingByKey(userSettingsKeys.TABLE_COLUMNS_CONFIG)"
      />
      <Paywall v-if="organization" />
      <ResumeSubscription v-if="organization" />

      <v-navigation-drawer
        v-if="$route.meta.showNav"
        v-model="drawerNav"
        app
        dark
        class="hide-scrollbar"
        color="primary darken-1"
        :mini-variant="true"
        :permanent="true"
      >
        <v-list-item dense class="px-2">
          <v-list-item-avatar tile>
            <v-img :src="require('@/assets/img/brand/Symbo_Icon_White.svg')" />
          </v-list-item-avatar>
        </v-list-item>
        <v-list dense nav>
          <v-list-item-group v-for="(item, i) in appNavItems" :key="i">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-list-item
                  v-on="on"
                  link
                  style="max-width: 40px"
                  :to="item.getLink?.()"
                  :class="{ 'v-list-item--active': isChildActive(item) }"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
            <v-divider v-if="item.divider" class="my-2 bg-white" />
          </v-list-item-group>
        </v-list>
        <div
          class="text-xs primary--text text--lighten-3 font-weight-bold d-flex justify-center w-100"
          style="position: absolute; bottom: 12px"
        >
          v{{ version }}
        </div>
      </v-navigation-drawer>
    </template>

    <phone-verification v-if="isLoggedIn && !isVerifiedUser" />
    <vue-progress-bar />

    <v-app-bar
      v-if="$route.meta.showNav && isVerifiedUser"
      app
      :height="appWarningBanner ? 72 : 52"
      color="white"
      class="d-flex flex-column symbo-app-bar"
      elevation="1"
    >
      <app-warning v-if="isLoggedIn && appWarning" />
      <div class="d-flex align-center w-100 py-1 px-4" style="height: 52px">
        <nav-title />
        <v-spacer />
        <universal-search />
        <universal-add-btn />

        <v-spacer />
        <div v-for="(group, i) in quickNavIcons" :key="i">
          <div v-for="(item, j) in group.data" :key="j" class="cursor-pointer">
            <component v-if="item.component" :is="item.component" />
            <v-tooltip v-else open-delay="100" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-avatar
                    class="mr-2"
                    v-on="on"
                    v-bind="attrs"
                    size="30"
                    :color="hover ? 'grey lighten-2' : 'white'"
                    @click="handleSecondaryNavClick(item)"
                  >
                    <span class="grey--text text--darken-2 text-h7">
                      <v-icon class="text--darken-2" size="22">{{
                        item.icon
                      }}</v-icon>
                    </span>
                  </v-avatar>
                </v-hover>
              </template>
              {{ item.title }}
            </v-tooltip>
          </div>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-tooltip open-delay="100" bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <user-avatar
                      :disableTooltip="true"
                      :readonly="true"
                      :elevated="true"
                      :value="user"
                    />
                  </div>
                </template>
                <v-col>
                  <v-row class="font-weight-bold">
                    {{ userFullName }}
                  </v-row>
                  <v-row>{{ (user || {}).email || null }}</v-row>
                </v-col>
              </v-tooltip>
            </div>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list dense>
              <v-subheader class="text-body-2 font-weight-bold text-uppercase">
                {{ userFullName }}
              </v-subheader>
              <v-list-item-group v-for="(group, i) in secondaryNav" :key="i">
                <v-list-item
                  v-for="(item, j) in group.data"
                  active-class="secondary-nav"
                  :v-if="group.display"
                  :key="j"
                  :class="$route.path === item.getLink?.() ? 'bg-selected' : ''"
                  @click="handleSecondaryNavClick(item)"
                >
                  <v-list-item-icon>
                    <v-icon
                      v-if="item.icon"
                      :active-class="
                        item.color
                          ? `${item.color}--text`
                          : 'grey--text text--darken-3'
                      "
                      :class="
                        item.color
                          ? `${item.color}--text`
                          : 'grey--text text--darken-3'
                      "
                      :color="item.color"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <v-img
                      v-else-if="item.img"
                      height="24"
                      width="24"
                      :src="item.img"
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      :active-class="
                        item.color
                          ? `${item.color}--text`
                          : 'grey--text text--darken-3'
                      "
                      :class="
                        item.color
                          ? `${item.color}--text`
                          : 'grey--text text--darken-3'
                      "
                    >
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <version-refresh-chip />
      </div>
    </v-app-bar>
    <v-main
      v-if="shouldShowRouterView"
      :style="`height: ${allowScroll ? 'auto' : appContentHeight}`"
      :class="!allowScroll ? 'overflow-hidden' : ''"
    >
      <quick-actions-bar v-if="quickActionServiceActive"></quick-actions-bar>
      <router-view />
    </v-main>

    <v-dialog v-model="versionDialog" width="400" persistent z-index="999999">
      <v-card id="version-dialog">
        <v-card-title class="border-b-1">
          <img
            class="ml-0 mr-3"
            style="height: 24px; width: 24px; object-fit: contain"
            :src="require('@/assets/img/brand/Symbo_Icon_Primary.svg')"
          />
          Time to Refresh!
        </v-card-title>
        <v-card-text class="py-4">
          An older version of the app was detected.
          <br />
          Please refresh the page now to keep things running smoothly!
        </v-card-text>
        <v-card-actions class="d-flex justify-end py-4 border-t-1">
          <v-btn color="primary" @click="refreshApp()">
            <v-icon class="mr-2">mdi-refresh</v-icon>
            Refresh now
          </v-btn>
          <v-btn
            color="amber darken-3"
            class="white--text"
            @click="versionDialog = false"
          >
            <v-icon class="mr-2">mdi-timer-refresh</v-icon>
            Remind later
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Refresh screen dialog (If current user's plan gets updated) -->
    <v-dialog v-model="refreshScreenDialog" persistent width="450">
      <v-card>
        <v-card-title class="text-h5 border-b-1">
          Your plan has been updated
        </v-card-title>
        <v-card-text class="pt-4">
          <div class="w-100 d-flex justify-center text-h6">
            Your screen will refresh in
          </div>
          <div class="w-100 d-flex justify-center text-h5 mb-3">
            {{ refreshScreenCountDown }} seconds
          </div>
          <div>
            Your plan update will change your access to Symbo features. If this
            is unexpected, please contact your admin.
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            class="d-flex align-center"
            @click="refreshNow"
          >
            <v-icon class="mr-2">mdi-refresh</v-icon>
            Refresh now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Switch to full mode prompt -->
    <v-dialog v-model="switchToFullModeDialog" persistent width="400">
      <v-card>
        <v-card-title class="border-b-1 py-2">
          Switch to "Pro interface"
        </v-card-title>
        <v-card-text class="pt-4">
          Symbo <strong>Power dial interface</strong> is focused around call
          features only. Switch <strong>Pro interface</strong> to access other
          features.
        </v-card-text>
        <v-card-actions class="d-flex justify-end border-t-1 py-2">
          <v-btn
            class="mr-2 white--text"
            color="grey"
            @click="
              switchToFullModeDialog = false
              targetFullModeRoute = null
            "
          >
            Cancel
          </v-btn>
          <v-btn color="primary" @click="switchToFullMode"> Switch </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add snippets dialog -->
    <v-dialog
      v-model="addSnippetsDialog"
      style="z-index: 1001 !important"
      max-width="600px"
    >
      <add-snippets @close="addSnippetsDialog = false" />
    </v-dialog>
  </v-app>
</template>

<style>
.secondary-nav.v-list-item--active:before {
  opacity: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as Sentry from '@sentry/vue'

import PhoneVerification from '@/components/utilities/PhoneVerification'

import { dialerServiceGetters } from '@/services/stateful/dialer/DialerService'
import { quickMenuServiceComputed } from '@/services/stateful/QuickMenuService'
import {
  powerDialComputed,
  powerDialActions,
} from '@/services/stateful/dialer/PowerDialService'
import { EventBus } from '@/services/event-bus'
import FeatureFlags from '@/services/feature-flags/FeatureFlags'
import cubejsApi from '@/services/cubejs/index'
import UtilsApi from '@/services/api/Utils'

import { UserSettings, defaultProspectFieldsConfig } from '@/constants'
import { removeLSItem } from '@/utils/localStorage'
import { HIJACK_LS_KEY, isHijackMode } from '@/utils/localStorage'
import { getLSItem, setLSItem } from './utils/localStorage'
import { generateProspectFieldsConfig } from '@/utils/helpers'

export default {
  name: 'App',
  components: {
    PhoneVerification,
    Snackbar: () => import('@/components/utilities/Snackbar'),
    CRMReauthSnackbar: () => import('@/components/utilities/CRMReauthSnackbar'),
    AppModal: () => import('@/components/utilities/AppModal'),
    CustomizeColumnsDrawer: () =>
      import('@/components/utilities/CustomizeColumnsDrawer'),
    QuickActionsBar: () => import('@/components/quick-menu/QuickActionsBar'),
    QuickActionsDrawer: () =>
      import('@/components/quick-menu/QuickActionsDrawer'),
    NavTitle: () => import('@/components/nav/NavTitle.vue'),
    HelpChat: () => import('@/components/help/HelpChat'),
    Dialer: () => import('@/components/dialer/Dialer'),
    DialerIcon: () => import('@/components/dialer/DialerIcon'),
    NotificationCenter: () => import('@/components/global/NotificationCenter'),
    HelpIcon: () => import('@/components/help/HelpIcon'),
    InviteUser: () => import('@/components/help/InviteUser'),
    EmailIcon: () => import('@/components/emailComposer/EmailIcon.vue'),
    Paywall: () => import('@/components/utilities/Paywall'),
    ResumeSubscription: () =>
      import('@/components/utilities/ResumeSubscription'),
    EmailComposer: () => import('@/components/emailComposer/EmailComposer.vue'),
    TestEmail: () => import('@/components/utilities/TestEmail.vue'),
    VersionRefreshChip: () =>
      import('@/components/utilities/VersionRefreshChip.vue'),
    UserAvatar: () => import('@/components/utilities/UserAvatar.vue'),
    UniversalSearch: () =>
      import('@/components/universal-search/UniversalSearch.vue'),
    AppWarning: () => import('@/components/app/AppWarning.vue'),
    PowerDialOngoingCall: () =>
      import('@/components/power-dialing/PowerDialOngoingCall.vue'),
    CSVUploader: () => import('@/components/utilities/CSVUploader.vue'),
    UniversalAddBtn: () =>
      import('@/components/universal-search/UniversalAddBtn.vue'),
    CreatePerson: () => import('@/components/contacts/CreatePerson.vue'),
    CreateAccount: () => import('@/components/accounts/CreateAccount.vue'),
    ActionFlowMode: () => import('@/components/utilities/ActionFlowMode.vue'),
    ContactDetailsDialog: () =>
      import('@/components/contacts/ContactDetailsDialog.vue'),
    WorkflowPrompt: () => import('@/components/utilities/WorkflowPrompt.vue'),
    AddSnippets: () => import('@/components/utilities/AddSnippets.vue'),
  },
  computed: {
    ...quickMenuServiceComputed,
    ...mapGetters('user', [
      'user',
      'userLoading',
      'isVerifiedUser',
      'organization',
      'isOrgAdmin',
      'getSettingByKey',
      'isLoggedIn',
      'appWarning',
      'appWarningBanner',
      'hasCalling',
      'adminCanManageCalls',
      'hasPowerDialing',
      'adminCanManagePowerDialing',
      'shouldShowCallPages',
      'isHijackMode',
      'isHubspotWidget',
      'isGHLWidget',
      'isLiteMode',
    ]),
    ...mapGetters('version', ['newVersionAvailable']),
    ...mapGetters('orgUsers', ['orgUsers']),
    ...mapGetters('pusher', ['channel']),
    ...mapGetters('prospectFields', ['customProspectFields']),

    // Power Dial Service - Computed
    pdSessionsUsersQuery: powerDialComputed.pdSessionsUsersQuery,
    ongoingPowerDialCall: powerDialComputed.ongoingPowerDialCall,

    // Dialer Service - Computed
    onCallState: dialerServiceGetters.onCallState,

    quickNavIconGroups() {
      return [
        ...(this.isLiteMode
          ? []
          : [
              {
                permission: 'admin',
                data: [
                  {
                    component: 'InviteUser',
                  },
                ],
              },
            ]),
        {
          permission: 'user',
          data: [
            {
              component: 'HelpIcon',
            },
          ],
        },
        {
          permission: 'user',
          featureFlag: 'isAppNotificationsEnabled',
          data: [
            {
              component: 'NotificationCenter',
            },
          ],
        },
        ...(this.isLiteMode
          ? []
          : [
              {
                permission: 'user',
                data: [
                  {
                    title: 'Quick Action Menu',
                    component: 'QuickActionsDrawer',
                    icon: '$vuetify.icons.quickActionMenu',
                    borderColor: 'pink',
                    click: 'openQuickActionsDrawer',
                  },
                ],
              },
            ]),
        ...(this.isLiteMode
          ? []
          : [
              {
                permission: 'user',
                data: [
                  {
                    component: 'EmailIcon',
                  },
                ],
              },
            ]),
        {
          permission: 'user',
          data: [
            {
              component: 'DialerIcon',
            },
          ],
        },
      ]
    },

    secondaryNavGroups() {
      return this.isLiteMode
        ? [
            {
              permission: 'user',
              data: [
                {
                  title: 'Pro interface',
                  color: 'primary',
                  icon: 'mdi-professional-hexagon',
                  click: 'switchAppMode',
                },
              ],
            },
            {
              permission: 'admin',
              data: [
                {
                  title: 'Org Settings',
                  icon: 'mdi-account-cog',
                  getLink: () => '/org-settings',
                },
              ],
            },
            {
              permission: 'user',
              data: [
                {
                  title: 'My Settings',
                  icon: 'mdi-cog',
                  getLink: () => '/settings',
                },

                {
                  title: isHijackMode() ? 'Exit Hijack' : 'Logout',
                  icon: 'mdi-logout',
                  click: 'logoutClicked',
                },
              ],
            },
          ]
        : [
            {
              permission: 'user',
              data: [
                ...(this.hasPowerDialing || this.adminCanManagePowerDialing
                  ? [
                      {
                        title: 'Power dial interface',
                        color: 'primary',
                        icon: '$vuetify.icons.powerPhone',
                        click: 'switchAppMode',
                      },
                    ]
                  : []),
                {
                  title: 'My Settings',
                  icon: 'mdi-cog',
                  getLink: () => '/settings',
                },
              ],
            },
            {
              permission: 'admin',
              data: [
                {
                  title: 'Org Settings',
                  icon: 'mdi-account-cog',
                  getLink: () => '/org-settings',
                },
              ],
            },
            {
              permission: 'user',
              data: [
                {
                  title: 'View referral link',
                  icon: 'mdi-gift',
                  getLink: () => '/settings?type=user-profile',
                },
              ],
            },
            {
              permission: 'user',
              data: [
                {
                  title: 'Feature Request',
                  icon: 'mdi-lightbulb',
                  href: 'https://forms.clickup.com/25746723/f/rhq93-1991/Y2TCHWN23A7W4HLC87',
                  target: '_blank',
                },
                {
                  title: isHijackMode() ? 'Exit Hijack' : 'Logout',
                  icon: 'mdi-logout',
                  click: 'logoutClicked',
                },
              ],
            },
          ]
    },

    version() {
      return process.env.VUE_APP_VERSION
    },

    shouldShowRouterView() {
      if (!this.isLoggedIn) return !this.$route.meta.requiresAuth

      if (!this.orgUsers?.length) return

      return this.userLoading || this.isVerifiedUser
    },
    peopleSheetLicense() {
      return process.env.VUE_APP_PEOPLE_SHEET_LICENSE_KEY
    },
    appContentHeight() {
      if (this.$route.name === 'BookSlot') return ''

      let heightToSubtract = this.appWarningBanner ? 108 : 80
      heightToSubtract += this.quickActionServiceActive
        ? this.isMiniQuickActionsBar
          ? 28
          : 60
        : 0
      return `calc(100vh - ${heightToSubtract}px)`
    },
    allowScroll() {
      return this.$route.meta.scrollView
    },
    userFullName() {
      return `${this.user?.first_name || ''} ${this.user?.last_name || ''}`
    },
    userInitials() {
      const initials =
        (this.user?.first_name?.[0] || '') + (this.user?.last_name?.[0] || '')
      return initials.toUpperCase()
    },
    quickNavIcons() {
      // Apply breakpoint sm and xs to remove some items to avoid clutter on top bar
      const itemsBasedOnBreakPoint = this.$vuetify.breakpoint.smAndDown
        ? this.quickNavIconGroups.slice(1)
        : this.quickNavIconGroups

      return itemsBasedOnBreakPoint.filter((group) => {
        const isEnabled =
          !group.featureFlag || FeatureFlags[group.featureFlag]()
        const hasPermission =
          group.permission == 'user' ||
          (this.isOrgAdmin && group.permission == 'admin')
        return isEnabled && hasPermission
      })
    },
    secondaryNav() {
      // Apply breakpoint sm and xs to push in some items to avoid clutter on top bar
      const navItemsBasedOnBreakpoint = this.$vuetify.breakpoint.smAndDown
        ? [
            ...this.secondaryNavGroups.slice(
              0,
              this.secondaryNavGroups.length - 1
            ),
            {
              permission: 'admin',
              data: [
                {
                  title: 'Invite a user',
                  icon: 'mdi-account-multiple-plus',
                  getLink: () => '/org-settings',
                },
              ],
            },
            ...this.secondaryNavGroups.slice(
              this.secondaryNavGroups.length - 1
            ),
          ]
        : this.secondaryNavGroups

      // Apply user role to filter out admin/non-admin items
      const navItemsBasedOnUserRole = navItemsBasedOnBreakpoint.filter(
        (group) => {
          return (
            group.permission == 'user' ||
            (this.isOrgAdmin && group.permission == 'admin')
          )
        }
      )

      return navItemsBasedOnUserRole
    },
    appNavItems() {
      return this.isLiteMode
        ? [
            {
              title: 'Power Dialer',
              icon: '$vuetify.icons.powerPhone',
              getLink: () => '/calls/power-dialing',
            },
            {
              title: 'Analytics',
              icon: 'mdi-chart-box',
              getLink: () =>
                this.isOrgAdmin
                  ? '/calls/call-summary'
                  : `/calls/call-summary/${this.user.id}`,
            },
            {
              title: 'Call History',
              icon: 'mdi-history',
              getLink: () => '/calls/call-details',
            },
            ...(this.isOrgAdmin
              ? [
                  {
                    title: 'Live Calls',
                    icon: 'mdi-phone-ring',
                    getLink: () => '/calls/live-calls',
                  },
                ]
              : []),
          ]
        : [
            {
              title: 'Home',
              icon: 'mdi-home',
              getLink: () => '/',
            },
            {
              title: 'People',
              icon: 'mdi-account-group',
              getLink: () => '/people',
              divider: true,
            },
            ...this.getCallsNav(),
            {
              title: this.$t('Actions'),
              icon: 'mdi-check',
              getLink: () => '/actions',
            },
            {
              title: 'Sequence Management',
              icon: '$vuetify.icons.symboDrive',
              getLink: () => '/drives',
              divider: true,
            },
            ...this.getAnalyticsNav(),
          ]
    },
  },
  watch: {
    channel: {
      handler(newVal) {
        if (newVal != null) {
          this.unbindPusherObserver()
          this.bindPusherObserver()
        }
      },
      immediate: true,
    },
    orgUsers: {
      handler(val, oldVal) {
        if (!oldVal || val?.length !== oldVal?.length) {
          if (this.isOrgAdmin) {
            const allTeam = val.map((u) => u.id).join(',')
            this.pdSessionsUsersQuery = allTeam
          }
          if (
            (this.hasPowerDialing || this.adminCanManagePowerDialing) &&
            !this.$route.path.includes('power-dialing')
          ) {
            powerDialActions.fetchActivePDSession()
            powerDialActions.fetchPDSessionsOnHold()
          }
        }
      },
      immediate: true,
    },
    user: {
      handler(newVal, oldVal) {
        if (newVal != null) {
          this.$i18n.locale = this.getSettingByKey(UserSettings.LANGUAGE)
          if (this.isLoggedIn && newVal.id !== oldVal?.id)
            this.setupSentryInfo()
        }
      },
      immediate: true,
    },
  },
  data: () => ({
    targetFullModeRoute: null,
    switchToFullModeDialog: false,

    addSnippetsDialog: false,
    versionDialog: false,
    userProfilePollingInterval: null,
    quickActionsDrawer: false,
    drawerNav: false,
    userSettingsKeys: UserSettings,

    refreshScreenDialog: false,
    refreshScreenCountDown: null,
    refreshScreenCountDownInterval: null,
  }),
  created: async function () {
    await this.fetchOrgUsers()

    setInterval(async () => {
      await this.fetchLatestVersion()
      this.$nextTick(() => {
        if (
          this.newVersionAvailable &&
          !this.onCallState &&
          !this.ongoingPowerDialCall &&
          this.isLoggedIn
        )
          this.versionDialog = true
      })
    }, 5 * 60000) // Check every five minutes

    EventBus.$on('show-full-mode-prompt', (to) => {
      this.targetFullModeRoute = to
      this.switchToFullModeDialog = true
    })

    EventBus.$on('open-version-dialog', () => {
      console.log('Event caught in App.vue open-version-dialog')
      this.versionDialog = true
    })

    EventBus.$on('add-snippet', () => (this.addSnippetsDialog = true))

    EventBus.$on('set-org-prospect-fields-config', () =>
      this.setOrgProspectFieldsConfig()
    )

    window.addEventListener(
      'error',
      (message, source, lineno, colno, error) => {
        if (
          error &&
          (error.name === 'ChunkLoadError' ||
            message?.includes('Refused to execute script'))
        ) {
          console.log('ChunkLoadError caught in window.onerror SYMBO')
          this.versionDialog = true
          this.reportChunkLoadError()
        }
        console.error('Global error:', message, source, lineno, colno, error)
      }
    )

    window.addEventListener('unhandledrejection', (event) => {
      const error = event.reason
      if (error && error.name === 'ChunkLoadError') {
        console.log('ChunkLoadError caught in window.onunhandledrejection')
        this.versionDialog = true
        this.reportChunkLoadError()
        console.log('version dialog set to true', this.versionDialog)
      }
      console.error('Unhandled rejection:', error)
    })

    EventBus.$on('import-people', (e) => {
      this.$refs.csvUploader.triggerOpen(e)
    })
    EventBus.$on('log-user-out', this.logout)
    EventBus.$on('soketi-disconnected', () => {
      this.pusherUnsubscribe()
      this.setupSoketi()
      this.pusherSubscribe()
    })
    // EventBus.$on('fetch-pending-action-counts', () => this.loadPendingActions())
    if (this.$vuetify.breakpoint.mdAndUp) this.drawerNav = true
    this.setupPollingUserProfile()
  },
  updated() {
    if (this.$route.query?.ghl) setLSItem('is-ghl-widget', true)

    if (getLSItem('is-hubspot-widget') && !this.isHubspotWidget)
      this.setHubspotWidget(true)
    if (getLSItem('is-ghl-widget') && !this.isGHLWidget) this.setGHLWidget(true)
  },
  beforeDestroy() {
    EventBus.$off('import-people')
    EventBus.$off('log-user-out')
    EventBus.$off('soketi-disconnected')
    EventBus.$off('set-org-prospect-fields-config')

    this.unbindPusherObserver()
    this.resetPollingUserProfile()
  },
  methods: {
    ...mapActions('pusher', [
      'pusherUnsubscribe',
      'pusherSubscribe',
      'setupSoketi',
    ]),
    ...mapActions('version', ['fetchLatestVersion']),
    ...mapMutations('appModal', ['showModal']),
    ...mapMutations('user', [
      'setHubspotWidget',
      'setGHLWidget',
      'setIsLiteMode',
    ]),
    ...mapActions('orgUsers', ['fetchOrgUsers']),
    ...mapActions('user', [
      'setSettingUpdateAsync',
      'getCrm',
      'load',
      'updateOrgSetting',
    ]),
    ...mapActions('prospectFields', ['fetchProspectFields']),

    switchToFullMode() {
      removeLSItem('lite')
      location.href = this.targetFullModeRoute.path
    },

    reportChunkLoadError() {
      UtilsApi.sendLog({
        browser_version: this.version,
        type: 'CHUNK_LOAD_ERROR',
      })
    },
    refreshApp() {
      location.reload()
    },
    resetPollingUserProfile() {
      clearInterval(this.userProfilePollingInterval)
      this.userProfilePollingInterval = null
    },
    setupPollingUserProfile() {
      const timeStep = 10 * 60 * 1000 // every ten mins
      this.resetPollingUserProfile()
      this.userProfilePollingInterval = setInterval(() => {
        if (this.isLoggedIn)
          this.load(true).then(() => {
            /*
            reinit cubejs
            [
              calling this would reinit the cube api
              instance and set it to Vue prototype so
              that all components get latest cube instance
            ]
            */
            cubejsApi()
            EventBus.$emit('user-profile-refreshed')
          })
      }, timeStep)
    },
    bindPusherObserver() {
      if (!this.channel) return

      this.channel.bind('mailing:throttle-changed', () => {
        console.log('mailing:throttle-changed')
      })
      this.channel.bind('mailing:needs-attention', () => {
        console.log('mailing:needs-attention')
      })
      this.channel.bind('mailing:updated', () => {
        console.log('mailing:updated')
      })
      this.channel.bind('organization:plan-updated', () => {
        console.log('organization:plan-updated')
        this.$store.dispatch('user/load')
      })
      this.channel.bind('crm:org-or-provider-updated', () => {
        this.$store.commit('snackbar/setSnack', {
          snack:
            "The default CRM for your organization has been removed. This change won't impact your current tasks.",
          snackType: 'warning',
        })
        this.getCrm()
        this.load()
      })
      this.channel.bind('crm:deleted', () => {
        console.log('crm:deleted')
        this.getCrm()
        this.load()
      })
      this.channel.bind('user:billing-plan-updated', () => {
        console.log('user:billing-plan-updated')
        this.refreshScreen()
      })
      this.channel.bind('user:permissions-updated', () => {
        console.log('user:permissions-updated')
        this.load()
      })
    },
    unbindPusherObserver() {
      if (!this.channel) return

      this.channel.unbind('mailing:throttle-changed')
      this.channel.unbind('mailing:needs-attention')
      this.channel.unbind('mailing:updated')
      this.channel.unbind('organization:plan-updated')
      this.channel.unbind('crm:org-or-provider-updated')
      this.channel.unbind('crm:deleted')
      this.channel.unbind('user:billing-plan-updated')
      this.channel.unbind('user:permissions-updated')
    },
    refreshScreen() {
      this.refreshScreenDialog = true
      this.refreshScreenCountDown = 30
      this.refreshScreenCountDownInterval = setInterval(() => {
        if (this.refreshScreenCountDown === 0) {
          clearInterval(this.refreshScreenCountDownInterval)
          this.refreshScreenCountDownInterval = null
          location.reload()
        } else {
          this.refreshScreenCountDown -= 1
        }
      }, 1000)
    },
    refreshNow() {
      this.refreshScreenDialog = false
      clearInterval(this.refreshScreenCountDownInterval)
      this.refreshScreenCountDownInterval = null
      location.reload()
    },
    logoutClicked: function () {
      if (isHijackMode()) {
        localStorage.removeItem(HIJACK_LS_KEY)
        location.reload()
      } else
        this.showModal({
          title: 'Done working?',
          doIfConfirmed: this.logout,
        })
    },
    featureRequestClicked: function () {
      EventBus.$emit('feature-request-clicked')
    },
    logout: function () {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/login')
      })
      removeLSItem('activeTabs')
      removeLSItem('tableOps')
      removeLSItem('filters')
    },
    switchAppMode() {
      if (this.isLiteMode) {
        this.setIsLiteMode(false)
        removeLSItem('lite')
      } else {
        this.setIsLiteMode(true)
        if (
          !this.$route.path?.includes('calls') ||
          [
            '/calls/power-dialing/active',
            '/calls/power-dialing/completed',
          ].includes(this.$route.path)
        ) {
          location.href = '/calls'
          return
        }
      }
      location.reload()
    },
    setupSentryInfo: function () {
      Sentry.setUser({
        id: this.user.id,
        email: this.user.email,
        first_name: this.user?.first_name,
        last_name: this.user?.last_name,
        organization_name: this.user?.organization?.name,
        organization_id: this.user?.organization?.id,
      })
    },
    handleSecondaryNavClick(item) {
      if (item.href) {
        window.open(item.href, item.target)
      } else if (item.click) {
        this[item.click]()
      } else if (item.getLink && this.$route.path != item.getLink?.()) {
        this.$router.push(item.getLink?.())
      }
    },
    openQuickActionsDrawer() {
      this.quickActionsDrawer = true
    },
    getAnalyticsNav() {
      const analyticsLink = () =>
        this.isOrgAdmin
          ? '/analytics/performance'
          : `/analytics/email-summary/${this.user.id}`
      const analyticsNav = {
        title: 'Analytics',
        icon: 'mdi-chart-bar',
        getLink: analyticsLink,
      }
      return [analyticsNav]
    },
    getCallsNav() {
      if (!this.shouldShowCallPages) return []

      const callsLink = () => {
        const lastRouteInCalls = (getLSItem('lastRouteInSection') || {}).calls
        return lastRouteInCalls
          ? lastRouteInCalls
          : this.hasPowerDialing
          ? '/calls/power-dialing/active'
          : this.isOrgAdmin
          ? '/calls/call-summary'
          : `/calls/call-summary/${this.user.id}`
      }

      const callsNav = {
        title: 'Calls',
        icon: 'mdi-phone',
        getLink: callsLink,
      }
      return [callsNav]
    },
    isChildActive(item) {
      switch (item.title) {
        case 'Calls':
          return this.$route.path.includes('/calls')
        case 'Sequence Management':
          return (
            this.$route.path.includes('/drives') ||
            this.$route.path.includes('/drive')
          )
        default:
          return false
      }
    },
    setOrgProspectFieldsConfig() {
      const savedConfig =
        this.getSettingByKey(UserSettings.ORG_PROSPECT_CARD_FIELDS_CONFIG) || []
      const orgProspectFieldsConfig = generateProspectFieldsConfig(
        savedConfig,
        defaultProspectFieldsConfig,
        this.customProspectFields
      )
      const payload = {
        settings_key: UserSettings.ORG_PROSPECT_CARD_FIELDS_CONFIG,
        settings_value: orgProspectFieldsConfig,
      }
      this.updateOrgSetting(payload)
    },
  },
}
</script>

<style scoped>
.symbo-app-bar >>> .v-toolbar__content {
  flex-direction: column !important;
  padding: 0px !important;
}
</style>
