<template>
  <div class="slider">
    <v-carousel
      :show-arrows="false"
      continuous
      cycle
      hide-delimiters
      hide-delimiter-background
      height="100%"
    >
      <v-carousel-item>
        <v-sheet
          class="d-flex align-center justify-center"
          height="100%"
          color="primary"
        >
          <v-sheet
            class="d-flex align-content-center justify-content-center"
            color="primary"
            max-width="80%"
          >
            <div>
              <div class="d-flex justify-center">
                <v-img
                  :src="dialerGroup"
                  alt="dialer"
                  max-width="400px"
                ></v-img>
              </div>
              <div class="d-flex justify-center">
                <div class="mt-8">
                  <div
                    class="font-thiccboi white--text font-weight-bold"
                    style="font-size: 2.5em"
                  >
                    4x Connection Rates
                  </div>
                  <div class="pt-2 white--text" style="font-size: 1.2em">
                    Connect more often with local presence number dialing
                    powered by Symbo's AI Sales Dialer. Our dialer also
                    includes:
                    <ul class="pt-1 text-body-2" style="list-style: none">
                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        Local Presence Dialing
                      </li>
                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        4 Line Power Dialing
                      </li>
                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        Call Recording & AI-powered Call Insights
                      </li>
                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        Voicemail Drops
                      </li>
                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        Text Messaging
                      </li>
                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        Call Coaching
                      </li>
                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        Double Dial
                      </li>

                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        Call Sync with your CRM
                      </li>
                      <li>
                        <v-icon small class="pr-2 white--text">
                          mdi-check-bold
                        </v-icon>
                        Call Outcome Triggers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </v-sheet>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
import dialerGroup from '@/assets/img/slider/dialer-group.svg'
export default {
  name: 'SliderContainer',
  data() {
    return {
      dialerGroup,
    }
  },
}
</script>
<style lang="scss">
.slider {
  background-color: #324499;
  max-width: 100rem;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.flip {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
</style>
