import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import messageListenerService from './services/messages/listeners'
import * as Sentry from '@sentry/vue'
import VueProgressBar from 'vue-progressbar'
import { registerGlobalComponents } from './helpers/registerGlobalComponents'
import { StripePlugin } from '@vue-stripe/vue-stripe'
import Gleap from 'gleap'

import './assets/css/Global.css'

import i18n from './i18n-setup'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data && event.data.action === 'skipWaiting') {
      console.log('Forcing service worker activation...')
      navigator.serviceWorker.getRegistration().then((reg) => {
        if (reg && reg.waiting) {
          reg.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
      })
    }
  })

  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker Registered:', registration)

        registration.addEventListener('updatefound', () => {
          const newSW = registration.installing
          if (newSW) {
            newSW.addEventListener('statechange', (e) => {
              console.log('statechange', e)
              if (
                newSW.state === 'installed' &&
                navigator.serviceWorker.controller
              ) {
                console.log('New service worker available, activating it...')

                // ✅ Send message to service worker to activate immediately
                newSW.postMessage({ type: 'SKIP_WAITING' })
              }
            })
          }
        })
      })
  })
}

// Store the original console.warn function
const originalConsoleWarn = console.warn

// Create a custom function to capture logs
console.warn = function (...args) {
  // Call the original console.warn so it still prints to the console
  originalConsoleWarn.apply(console, args)

  // Add your custom behavior here
  if (args) handleConsoleWarn(args)
}

// Function to handle captured logs
function handleConsoleWarn(logData) {
  const isPlivo = logData?.[0]?.includes('PlivoSDK')
  if (isPlivo) {
    const data = logData?.[1]
    if (data?.includes('Cannot login when there is no internet'))
      store.commit('snackbar/setSnack', {
        snack: `Hey! You've got network problems. This might affect your dialing capabilites. <br/> Try reloading or <a target="_blank" class="white--text font-weight-bold" href="https://help.symbo.ai/en/articles/10157834-network-firewall-setup">see doc for assistance.</a>`,
        snackType: 'warning',
        multiLine: true,
        permanent: true,
      })
  }
}

//setup message listening service
messageListenerService.addListners()

//setup Stripe
Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  testMode: process.env.VUE_APP_STRIPE_TEST_MODE || false,
})

//setup VueProgressBar
const progressBarOptions = {
  color: '#72CDE0',
  failedColor: '#ff5252',
  thickness: '3px',
}
Vue.use(VueProgressBar, progressBarOptions)

//setup Sentry
Sentry.init({
  Vue: Vue,
  environment: process.env.VUE_APP_SENTRY_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_SYMBO_UI_URL, /^\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        process.env.VUE_APP_SYMBO_API_URL,
        process.env.VUE_APP_SYMBO_ANALYTICS_URL,
      ],
    }),
  ],
  tracingOptions: {
    trackComponents: true,
  },
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    process.env.VUE_APP_SYMBO_API_URL,
    // process.env.VUE_APP_SYMBO_ANALYTICS_URL,
  ],
  logErrors: true,
  attachProps: true,
  attachStacktrace: true,
  maxBreadCrumbs: 5,
  debug: false,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
})

//setup Gleap
Gleap.initialize('38DrW5Ovhnu4EuReRtDcz7fkGA8t3iCt')

// import global components
registerGlobalComponents(Vue)

Vue.config.productionTip = false

// v-visible custom directive
Vue.directive('visible', (el, binding) => {
  el.style.visibility = binding.value ? 'visible' : 'hidden'
})

Vue.config.errorHandler = function (err, vm, info) {
  console.error(`Error: ${err.toString()}\nInfo: ${info}`)
  return true
}

Vue.config.warnHandler = function (msg, vm, trace) {
  console.warn(`Warning: ${msg}\nTrace: ${trace}`)
  return true
}

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
