export default Object.freeze({
  ACCOUNT_CARD_FIELDS_CONFIG: 'accountCardFieldsConfig',
  ACCOUNT_INDUSTRIES: 'accountIndustries',
  ADMIN_CAN_MANAGE_CALLS: 'adminCanManageCalls',
  ADMIN_CAN_MANAGE_POWER_DIALING: 'adminCanManagePowerDialing',
  ADIMN_CAN_VIEW_TRANSCRIPTION: 'adminCanViewTranscription',
  ALLOW_USERS_TO_TOGGLE_CALL_RECORDING: 'allowUsersToToggleCallRecording',
  AUTO_IMPORT_PEOPLE_BY_DIALED_NUMBER: 'autoImportPeopleByDialedNumber',
  AUTO_RECORD_CALLS: 'autoRecordCalls',
  AUTO_REMOVE_ERRONEOUS_CSV_CELLS: 'autoRemoveErroneousCSVCells',
  AUTO_RESUME_PD_AFTER_VM_DROP: 'autoResumePDAfterVMDrop',
  BLOCK_CALLING_DNC_NUMBERS: 'blockCallingDNCNumbers',
  CAN_ACCESS_OTHER_USER_PROSPECTS: 'canAccessOtherUserProspects',
  CAN_CREATE_SIGNATURE: 'canCreateSignature',
  CAN_CREATE_SEQUENCE: 'canCreateSequence',
  CAN_EDIT_SHARED_SEQUENCE: 'canEditSharedSequence',
  CAN_EDIT_SHARED_FILTERS: 'canEditSharedFilters',
  CAN_ARCHIVE_SHARED_SEQUENCE: 'canArchiveSharedSequence',
  CAN_REASSIGN_CONTACT: 'canReAssignContact',
  CAN_ACCESS_CRM_REPORTS: 'canAccessCRMReports',
  CAN_ACCESS_CRM_CONTACTS: 'canAccessCRMContacts',
  CALENDAR_INVITE_EMAIL: 'calendarInviteEmail',
  CALL_DISPOSITIONS: 'callDispositions',
  CALL_RECORDING_OVERRIDES: 'callRecordingOverrides',
  CALL_SUMMARY_OUTCOMES_LIST_CONFIG: 'callSummaryOutcomeListConfig',
  CRM_SYNC_RULE: 'crmSyncRule',
  DASHBOARD_SELECTED_USERS: 'dashboardSelectedUsers',
  DASHBOARD_TAB: 'dashboardTab',
  DASHBOARD_VIEW: 'dashboardView',
  DEFAULT_FILTER_TABS_CONFIG: 'defaultFilterTabsConfig',
  DISALLOW_DUPLICATE_PHONE_NUMBERS: 'disallowDuplicatePhoneNumbers',
  DISALLOW_DUPLICATE_EMAILS: 'disallowDuplicateEmails',
  DUPLICATE_CHECK_ALL_ORG: 'duplicateCheckAllOrg',
  DIAL_TONES_ENABLED: 'dialTonesEnabled',
  EMAIL_SIGNATURE: 'emailSignature',
  ENABLE_PROSPECT_STATUS_PD_FILTERING: 'enableProspectStatusPDFiltering',
  END_BUSINESS_HOURS: 'endBusinessHours',
  EXPAND_NOTES_ON_PD_CALL: 'expandNotesOnPDCall',
  HAS_CALLING: 'hasCalling',
  HAS_PD_AUTO_ANSWER_DETECTION: 'hasPDAutoAnswerDetection',
  HAS_PD_STREAM: 'hasPDStream',
  HAS_CALL_AUDIO_STORAGE: 'hasCallAudioStorage',
  HAS_LOCAL_PRESENCE: 'hasLocalPresence',
  HAS_MISSED_CALL: 'hasMissedCall',
  HAS_POWER_DIALING: 'hasPowerDialing',
  HAS_TRANSCRIPTION: 'hasTranscription',
  HAS_UNREAD_PHONE_MESSAGE: 'hasUnreadPhoneMessage',
  ISO_COUNTRY_CODE: 'isoCountryCode',
  LANGUAGE: 'language',
  LOG_OUTSIDE_MAILINGS_TO_CRM: 'logOutsideMailingsToCrm',
  LOG_OUTSIDE_MAILINGS_TO_SYMBO: 'logOutsideMailingsToSymbo',
  NOISE_SUPPRESSION_ENABLED: 'noiseSuppressionEnabled',
  NOTIFICATION_EMAILS_ENABLED: 'notificationEmailsEnabled',
  NOTIFICATION_SOUND_ENABLED: 'notificationSoundEnabled',
  ONE_CLICK_DIAL_ENABLED: 'oneClickDialEnabled',
  LAST_CALL_SAVE_BTN_USED: 'lastCallSaveBtnUsed',
  OUTBOUND_CALLER_ID: 'lastUsedOutboundCallerId',
  PD_CALENDAR_CONFIG: 'pdCalendarConfig',
  PD_CALL_PROSPECT_FIELDS_CONFIG: 'pdCallProspectFieldsConfig',
  PD_SESSION_CONFIG: 'pdSessionConfig',
  LOCK_ORG_PD_CONFIG: 'lockOrgPdConfig',
  POWER_DIAL_MINUTES_PER_USER: 'powerDialMinutesPerUser',
  PROSPECT_CARD_FIELDS_CONFIG: 'prospectCardFieldsConfig',
  ORG_PROSPECT_CARD_FIELDS_CONFIG: 'orgProspectCardFieldsConfig',
  PROSPECT_STATUSES: 'prospectStatuses',
  PROSPECTS_TEAM_SELECTION: 'prospectsTeamSelection',
  RING_IN_BROWSER: 'ringInBrowser',
  SAVED_PEOPLE_FILTERS: 'savedPeopleFilters',
  SHOW_CONFIRM_EMAIL_TOOLTIP: 'showConfirmEmailTooltip',
  SHOW_CLOSED_STATUS_PROSPECTS: 'showClosedStatusProspects',
  START_BUSINESS_HOURS: 'startBusinessHours',
  SUB_NAV_MINI_VARIANT: 'subNavMiniVariant',
  TABLE_COLUMNS_CONFIG: 'tableColumnsConfig',
  TIMEZONE: 'timezone',
  USE_GROUPED_CALL_OUTCOMES: 'useGroupedCallOutcomes',
  USE_LOCAL_PRESENCE: 'useLocalPresence',
  US_AREA_CODE_OR_STATE_RESTRICTIONS: 'usAreaCodeOrStateRestrictions',
})
